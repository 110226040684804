import { useEffect, useState } from 'react'
import {
    useConnectWallet,
    useSetChain
} from '@web3-onboard/react'
import { getBSCVaultContract, getETHVaultContract } from '../utils/contractHelpers.tsx'



type UseMoreContractInfoState = {
    info: any
    fetchStatus: FetchStatus
}

export enum FetchStatus {
    NOT_FETCHED = 'not-fetched',
    SUCCESS = 'success',
    FAILED = 'failed',
}


export const useContractInfo = () => {
    const [{ wallet }] = useConnectWallet()
    const [{ connectedChain }] = useSetChain()
    const { NOT_FETCHED, SUCCESS, FAILED } = FetchStatus
    const [ContractInfoState, setContractInfoState] = useState<UseMoreContractInfoState>({
        info: {
            investAmount: String,
            rewardAmount: String,
        },
        fetchStatus: NOT_FETCHED,
    })

    useEffect(() => {
        const fetchContractInfo = async () => {
            let VaultContract;
            if (connectedChain.id === "0x38") {
                VaultContract = getBSCVaultContract()
            } else {
                VaultContract = getETHVaultContract()
            }
            try {
                const ContractInterface = await VaultContract.players(wallet.accounts[0].address)
                const _investedAmount = await ContractInterface.userTotalInvested;
                const _rewardAmount = await ContractInterface.userTotalWithdrawed;
                let _ETHPoolInvestedAmount;
                let _SoloPoolInvestedAmount;
                let _BNBPoolInvestedAmount;
                if (connectedChain.id === "0x1") {
                    _ETHPoolInvestedAmount = await ContractInterface.userETHPoolInvested;
                    _SoloPoolInvestedAmount = await ContractInterface.userSoloPoolInvested;
                } else {
                    _BNBPoolInvestedAmount = await ContractInterface.userTotalInvested
                }
                setContractInfoState({
                    info: {
                        investAmount: _investedAmount,
                        rewardAmount: _rewardAmount,
                        ETHPoolInvestedAmount: _ETHPoolInvestedAmount,
                        SoloPoolInvestedAmount: _SoloPoolInvestedAmount,
                        BNBPoolInvestedAmount: _BNBPoolInvestedAmount
                    },
                    fetchStatus: SUCCESS
                })
            } catch (e) {
                console.error(e)
                setContractInfoState((prev) => ({
                    ...prev,
                    fetchStatus: FAILED,
                }))
            }
        }

        if (wallet) {
            fetchContractInfo()
        }
    }, [wallet,connectedChain, FAILED, SUCCESS])

    return ContractInfoState
}

