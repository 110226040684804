import { ethers } from 'ethers'
import BSCContractAbi from '../config/BSCContract.json'
import ETHContractAbi from '../config/ETHContract.json'

const BSC_RPC_URL = 'https://bsc-dataseed.binance.org/'
const ETH_RPC_URL = 'https://eth.llamarpc.com'

const simpleBSCRpcProvider = new ethers.providers.JsonRpcProvider(BSC_RPC_URL)
const simpleETHRpcProvider = new ethers.providers.JsonRpcProvider(ETH_RPC_URL)

console.log(simpleBSCRpcProvider, simpleETHRpcProvider, 'debug->getFeeData')

const getBSContract = (abi: any, address: string, signer?: ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleBSCRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

const getETHContract = (abi: any, address: string, signer?: ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleETHRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const BSCContractAddress = '0x1ab93AB4AdB4D6a915fccE56770d63AdFB91880f';
export const ETHContractAddress = '0x8ecEbc2BF559D96c663E6cCD80e2C78431E0d9E9';

export const getBSCVaultContract = (signer?: ethers.providers.Provider) => {
  return getBSContract(BSCContractAbi, BSCContractAddress, signer)
}

export const getETHVaultContract = (signer?: ethers.providers.Provider) => {
  return getETHContract(ETHContractAbi, ETHContractAddress, signer)
}
