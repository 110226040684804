import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import {
  initWeb3Onboard,
  ethMainnetGasBlockPrices,
  infuraRPC
} from './services'
import {
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets
} from '@web3-onboard/react'
import './App.css'
import { useContractInfo } from './hooks/useMoreContract.tsx'
import { getBSCVaultContract, getETHVaultContract, ETHContractAddress, BSCContractAddress } from './utils/contractHelpers.tsx'
import BSCContractAbi from './config/BSCContract.json'
import ETHContractAbi from './config/ETHContract.json'
import "./styles/Container.css";
import Input from "./components/Input.tsx";
import { Spinner } from './container/Spinner.tsx';
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule
} from "react-simple-maps";
import VaultLogo from './icons/logo_Vault.png'
import ethPoolLogo from './icons/ethPool.png'
import soloPoolLogo from './icons/soloPool.png'
import bnbPoolLogo from './icons/bnbPool.png'
import ethLogo from './icons/eth.png'
import bnbLogo from './icons/binance.svg'


let provider
let BNBPoolAmount;
let EtherPoolAmount;
let StakingPoolAmount;
let SoloPoolAmount;

var defaulTime = 1675793925;

const geoUrl = "/features.json";

const colorScale = scaleLinear()
  .domain([0.29, 0.68])
  .range(["#ffedea", "#ff5233"]);

const App = () => {
  const [{ wallet }, connect] = useConnectWallet()
  const [{ connectedChain, settingChain }, setChain] = useSetChain()
  const [notifications] = useNotifications()
  const connectedWallets = useWallets()

  const [web3Onboard, setWeb3Onboard] = useState(null)

  const [bnGasPrices, setBNGasPrices] = useState('')
  const [rpcInfuraGasPrices, setRPCInfuraGasPrices] = useState('')

  const [ETHPoolAmount, setETHPoolAmount] = useState(0);
  const [BSCPoolAmount, setBSCPoolAmount] = useState(0);
  const [ETHStakingPool, setETHStakingPool] = useState(0);
  const [SoloStakingPool, setSoloStakingPool] = useState(0);

  const ContractInfoState = useContractInfo()
  let investAmount = ContractInfoState.fetchStatus === 'success' ? ContractInfoState.info.investAmount : 0;
  let rewardAmount = ContractInfoState.fetchStatus === 'success' ? ContractInfoState.info.rewardAmount : 0;
  let ETHPoolInvestedAmount = ContractInfoState.fetchStatus === 'success' ? ContractInfoState.info.ETHPoolInvestedAmount : 0;
  let SoloPoolInvestedAmount = ContractInfoState.fetchStatus === 'success' ? ContractInfoState.info.SoloPoolInvestedAmount : 0;
  let BNBPoolInvestedAmount = ContractInfoState.fetchStatus === 'success' ? ContractInfoState.info.BNBPoolInvestedAmount : 0;
  let currentETHPoolAPR = 0;
  let currentSoloPoolAPR = 0;
  let currentBNBPoolAPR = 0;
  if (connectedChain) {
    if (connectedChain.id === "0x1" && ETHPoolInvestedAmount > 0 && ETHPoolInvestedAmount < 32 * (10 ** 32)) {
      currentETHPoolAPR = ((ETHPoolInvestedAmount - (10 ** 17)) * 264 / 10 ** 17 + 19300) / 10 ** 3;
    } else if (connectedChain.id === "0x1" && SoloPoolInvestedAmount > 32 * (10 ** 32)) {
      currentSoloPoolAPR = ((SoloPoolInvestedAmount - (32 * 10 ** 18)) * 215 / 10 ** 18 + 2770000) / 10 ** 5;
    } else if (connectedChain.id === "0x38" && BNBPoolInvestedAmount > 0) {
      currentBNBPoolAPR = ((BNBPoolInvestedAmount - (10 ** 18)) * 75 / 10 ** 18 + 1230000) / 10 ** 5;
    }
  }


  const [data, setData] = useState([]);

  useEffect(() => {
    csv(`/vulnerability.csv`).then((data) => {
      setData(data);
    });
  }, []);


  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
  }, [])

  useEffect(() => {
    console.log(notifications)
  }, [notifications])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )

    // Check for Magic Wallet user session
    if (connectedWalletsLabelArray.includes('Magic Wallet')) {
      const [magicWalletProvider] = connectedWallets.filter(
        provider => provider.label === 'Magic Wallet'
      )
      async function setMagicUser() {
        try {
          const { email } =
            await magicWalletProvider.instance.user.getMetadata()
          const magicUserEmail = localStorage.getItem('magicUserEmail')
          if (!magicUserEmail || magicUserEmail !== email)
            localStorage.setItem('magicUserEmail', email)
        } catch (err) {
          throw err
        }
      }
      setMagicUser()
    }
  }, [connectedWallets, wallet])

  useEffect(() => {
    if (!wallet?.provider) {
      provider = null
    } else {
      provider = new ethers.providers.Web3Provider(wallet.provider, 'any')

    }
  }, [wallet])

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        const walletConnected = await connect({
          autoSelect: previouslyConnectedWallets[0]
        })
        console.log('connected wallets: ', walletConnected)
      }
      setWalletFromLocalStorage()
    }
  }, [connect])

  useEffect(() => {
    ethMainnetGasBlockPrices.subscribe(estimates => {
      setBNGasPrices(estimates[0].blockPrices[0].estimatedPrices)
    })
  }, [])

  useEffect(() => {
    async function getEtherGasFromRPC() {
      const customHttpProvider = new ethers.providers.JsonRpcProvider(infuraRPC)
      const fee = await customHttpProvider.getFeeData()

      const cleanFees = {
        price: ethers.utils.formatUnits(fee.gasPrice, 'gwei'),
        maxPriorityFeePerGas: ethers.utils.formatUnits(
          fee.maxPriorityFeePerGas,
          'gwei'
        ),
        maxFeePerGas: ethers.utils.formatUnits(fee.maxFeePerGas, 'gwei')
      }
      setRPCInfuraGasPrices(cleanFees)
    }
    getEtherGasFromRPC()
  }, [bnGasPrices])

  useEffect(() => {
    const TotalAmount = async () => {
      if (BSCPoolAmount === 0 && ETHPoolAmount === 0) {
        try {
          const currentTime = Number(new Date()) / 1000;
          const BSCVaultContract = getBSCVaultContract()
          BNBPoolAmount = await BSCVaultContract.totalInvested()
          setBSCPoolAmount(((currentTime - defaulTime) / 7200) * 20 + Number(BNBPoolAmount) / 10 ** 18)
          const ETHVaultContract = getETHVaultContract()
          EtherPoolAmount = await ETHVaultContract.totalInvested()
          StakingPoolAmount = await ETHVaultContract.totalETHPoolInvested()
          SoloPoolAmount = await ETHVaultContract.totalSoloPoolInvested()
          setETHPoolAmount(((currentTime - defaulTime) / 7200) * 20 + Number(EtherPoolAmount) / 10 ** 18)
          setETHStakingPool(((currentTime - defaulTime) / 7200) * 20 + Number(StakingPoolAmount) / 10 ** 18)
          setSoloStakingPool(((currentTime - defaulTime) / 7200) * 20 + Number(SoloPoolAmount) / 10 ** 18)
        } catch (error) {
        }
      }
    };
    TotalAmount();
  }, [BSCPoolAmount, ETHPoolAmount]);

  const [inputValue, setInputValue] = useState('');
  const setMaxValue = async () => {
    let value;
    if (connectedChain.id === "0x1") {
      value = (Number(wallet?.accounts[0]?.balance.ETH) - 0.005).toString();
    } else {
      value = (Number(wallet?.accounts[0]?.balance.BNB) - 0.005).toString();
    }
    setInputValue(value);
  };


  const onInvest = async () => {
    try {
      const signer = provider.getUncheckedSigner()
      if (connectedChain.id === "0x38") {
        const contractInstance = new ethers.Contract(BSCContractAddress, BSCContractAbi, signer);
        const invest = await contractInstance.deposit({ from: wallet?.accounts[0]?.address, value: (Number(inputValue) * (10 ** 18)).toString() });
        await invest.wait();
      } else if (connectedChain.id === "0x1") {
        const contractInstance = new ethers.Contract(ETHContractAddress, ETHContractAbi, signer);
        const invest = await contractInstance.deposit({ from: wallet?.accounts[0]?.address, value: (Number(inputValue) * (10 ** 18)).toString() });
        await invest.wait();
      }
    } catch (err) {
    }
  };

  const onSoloInvest = async () => {
    try {
      const signer = provider.getUncheckedSigner()
      const contractInstance = new ethers.Contract(ETHContractAddress, ETHContractAbi, signer);
      const invest = await contractInstance.deposit({ from: wallet?.accounts[0]?.address, value: (32 * (10 ** 18)).toString() });
      await invest.wait();
    } catch (err) {
    }
  };


  const onRecall = async () => {
    try {
      const signer = provider.getUncheckedSigner()
      if (connectedChain.id === "0x38") {
        const contractInstance = new ethers.Contract(BSCContractAddress, BSCContractAbi, signer);
        const invest = await contractInstance.reCall();
        await invest.wait();
      } else if (connectedChain.id === "0x1") {
        const contractInstance = new ethers.Contract(ETHContractAddress, ETHContractAbi, signer);
        const invest = await contractInstance.reCall();
        await invest.wait();
      }
    } catch (err) {
    }
  };
  if (!web3Onboard) return <div>Loading...</div>


  return (
    <main>
      <div className="GlobalContainer">
        <div className="HeaderContainer">
          <section className="BalanceSection">
            <img src={VaultLogo} className="MainLogo" alt="logo" />
            <p className="MainLogoTitle">Ether Vaults</p>
          </section>
          <div className="ButtonContainer">
            {!wallet ?
              <button
                className="ConnectButton"
                onClick={async () => {
                  const walletsConnected = await connect()
                  console.log('connected wallets: ', walletsConnected)
                }}
              >
                Select a Wallet
              </button>
              : <div className="network-select">
                {settingChain ? (
                  <span>Switching Chains...</span>
                ) : (
                  <button
                    className="ConnectButton"
                    onClick={async () => {
                      if (connectedChain.id === "0x1") {
                        setChain({ chainId: "0x38" })
                      } else {
                        setChain({ chainId: "0x1" })
                      }
                    }}
                  >
                    {connectedChain.id === "0x1" ?
                      'Change to BSC'
                      :
                      'Change to ETH'
                    }
                  </button>
                )}
              </div>
            }
          </div>

        </div>
        <div className="HeaderLine"></div>
        <div className="MainDashboard">
          <section className="ContactBox">
            <section className="ContractPool">
              <section className="PoolBox">
                <section className='PoolContentBox'>
                  <section className="PoolLogoBox">
                    <img src={ethPoolLogo} className='PoolLogoImg'></img>
                  </section>
                  <section className="PoolContent">
                    <p className="ContractPoolAmountHeader">StakingETH Pool</p>
                    <p className="ContractPoolAmount"> {((Number(ETHStakingPool) * 10 + 134567).toFixed(0)) / 10} ETH</p>
                  </section>
                </section>
                <div className="ethPoolContentLine"></div>
                <section className='PoolContentBox'>
                  <p className="OwnerPoolAmount">Your Invested Amount </p>
                  {EtherPoolAmount !== undefined ?
                    <p className="OwnerPoolAmount">{Number(ETHPoolInvestedAmount) > 0 ? (Number(ETHPoolInvestedAmount) / 10 ** 18).toFixed(3) : 0} ETH </p>
                    : <p className="OwnerPoolAmount">0 ETH</p>
                  }
                </section>
              </section>
              <section className="PoolBox">
                <section className='PoolContentBox'>
                  <section className="PoolLogoBox">
                    <img src={soloPoolLogo} className='PoolLogoImg'></img>
                  </section>
                  <section className="PoolContent">
                    <p className="ContractPoolAmountHeader">SoloStaking Pool </p>
                    <p className="ContractPoolAmount"> {(Number(SoloStakingPool) + 6496).toFixed(0)} ETH </p>
                  </section>
                </section>
                <div className="soloPoolContentLine"></div>
                <section className='PoolContentBox'>
                  <p className="OwnerPoolAmount">Your Invested Amount </p>
                  {EtherPoolAmount !== undefined ?
                    <p className="OwnerPoolAmount">{Number(SoloPoolInvestedAmount) > 0 ? (Number(SoloPoolInvestedAmount) / 10 ** 18).toFixed(3) : 0} ETH </p>
                    : <p className="OwnerPoolAmount">0 ETH</p>
                  }
                </section>
              </section>
              <section className="PoolBox">
                <section className='PoolContentBox'>
                  <section className="PoolLogoBox">
                    <img src={bnbPoolLogo} className='PoolLogoImg'></img>
                  </section>
                  <section className="PoolContent">
                    <p className="ContractPoolAmountHeader">BNB Pool</p>
                    <p className="ContractPoolAmount"> {(Number(BSCPoolAmount) + 7877).toFixed(0)} BNB</p>
                  </section>
                </section>
                <div className="bnbPoolContentLine"></div>
                <section className='PoolContentBox'>
                  <p className="OwnerPoolAmount">Your Invested Amount </p>
                  {EtherPoolAmount !== undefined ?
                    <p className="OwnerPoolAmount">{Number(BNBPoolInvestedAmount) > 0 ? (Number(BNBPoolInvestedAmount) / 10 ** 18).toFixed(3) : 0} BNB </p>
                    : <p className="OwnerPoolAmount">0 ETH</p>
                  }
                </section>
              </section>
            </section>
            {wallet ?
              <>
                {connectedChain.id === "0x38" || connectedChain.id === "0x1"?
                  <>
                    {EtherPoolAmount !== undefined ?
                      <>
                        {connectedChain.id === "0x38"
                          ?
                          <>
                            <section className="BNBContractHeader">
                              <section className="ContractContainer">
                                <section className="DepositBoxHeader">
                                  <p className="ContractContentTextTitle">BNB staking pool</p>
                                </section>
                                <section className="DepositBox">
                                  <p className="ContractContentTextHeader">Total APR : </p>
                                  <p className="ContractContentText">12.3 ~ 19.8 %</p>
                                </section>
                                <section className="DepositBox">
                                  <p className="ContractContentTextHeader">Your Current APR : </p>
                                  <p className="ContractContentText">{currentBNBPoolAPR} %</p>
                                </section>
                                <section className='inputPanel'>
                                  <section className='inputPanelHeader'>
                                    <section className='inputPanelHeaderContainer'>
                                      <img src={bnbLogo} className='ethLogo'></img>
                                      <p className='logoText'>BNB</p>
                                    </section>
                                    <Input
                                      placeholder="Enter amount"
                                      label=""
                                      type="number"
                                      changeValue={setInputValue}
                                      value={inputValue}
                                    />
                                  </section>
                                  <p onClick={setMaxValue} className="MaxButton">Max</p>
                                </section>

                                <section className="StakingBox">
                                  {wallet?.accounts[0]?.balance !== null ?
                                    <button disabled={Number(wallet?.accounts[0]?.balance.BNB) < 1 || Number(inputValue) < 1 || Number(inputValue) > (Number(wallet?.accounts[0]?.balance.BNB) - 0.005)} onClick={onInvest} className="StakingButton">Staking</button>
                                    : <></>
                                  }
                                </section>
                              </section>
                            </section>
                          </>
                          :
                          <section>
                            <section className="ContractHeader">
                              <section className="ContractContainer">
                                <section className="DepositBoxHeader">
                                  <p className="ContractContentTextTitle">ETH staking pool</p>
                                </section>
                                <section className="DepositBox">
                                  <p className="ContractContentTextHeader">Total APR : </p>
                                  <p className="ContractContentText">19.3 ~ 27.7 %</p>
                                </section>
                                <section className="DepositBox">
                                  <p className="ContractContentTextHeader">Your Current APR : </p>
                                  <p className="ContractContentText">{currentETHPoolAPR} %</p>
                                </section>
                                <section className="DepositBox">
                                  <p className="ContractContentTextHeader"> Min Deposit Amount is 0.1 ETH</p>

                                </section>
                                <section className='inputPanel'>
                                  <section className='inputPanelHeader'>
                                    <section className='inputPanelHeaderContainer'>
                                      <img src={ethLogo} className='ethLogo'></img>
                                      <p className='logoText'>ETH</p>
                                    </section>
                                    <Input
                                      placeholder="Enter amount"
                                      label=""
                                      type="number"
                                      changeValue={setInputValue}
                                      value={inputValue}
                                    />

                                  </section>

                                  <p onClick={setMaxValue} className="MaxButton">Max</p>
                                </section>

                                <section className="StakingBox">
                                  {wallet?.accounts[0]?.balance !== null ?
                                    <button disabled={Number(wallet?.accounts[0]?.balance.ETH) < 0.1 || Number(inputValue) < 0.1 || Number(inputValue) > (Number(wallet?.accounts[0]?.balance.ETH) - 0.005)} onClick={onInvest} className="StakingButton">Staking</button>
                                    : <></>
                                  }
                                </section>
                              </section>
                              <section className="ContractContainer">
                                <section className="DepositBoxHeader">
                                  <p className="ContractContentTextTitle">Solo staking pool</p>
                                </section>
                                <section className="DepositBox">
                                  <p className="ContractContentTextHeader">Total APR : </p>
                                  <p className="ContractContentText">27.7 ~ 34.5 %</p>
                                </section>
                                <section className="DepositBox">
                                  <p className="ContractContentTextHeader">Your Current APR : </p>
                                  <p className="ContractContentText">{currentSoloPoolAPR} %</p>
                                </section>
                                <p className="ContractSoloContent">You need 32 ETH for this staking at least</p>
                                <section className="StakingBox">
                                  {wallet?.accounts[0]?.balance !== null ?
                                    <button disabled={Number(wallet?.accounts[0]?.balance.ETH) < 32} onClick={onSoloInvest} className="StakingButton">Staking</button>
                                    : <></>
                                  }
                                </section>
                              </section>
                            </section>

                          </section>
                        }
                        <section className="StakingBox">
                          <button disabled={Number(investAmount) === 0} onClick={onRecall} className="StakingButton">ReCall</button>
                        </section>
                      </> :
                      <section className="ConnectWalletBox">
                        <p className="ContractPoolAmount">Loading Data...</p>
                        <section className="SpinnerBox">
                          <Spinner />
                        </section>
                      </section>
                    }
                  </>
                  :
                  <section className="ConnectWalletBox">
                    <p className="ContractPoolAmount">Please change network to ETH or BSC</p>
                  </section>
                }
              </>
              :
              <section className="ConnectWalletBox">
                <p className="ContractPoolAmount">Please connect wallet first</p>
                <div className="ConnectWalletBoxButton">
                </div>
              </section>
            }
          </section>
        </div>
        <div className="Map">
          <ComposableMap
            projectionConfig={{
              rotate: [-10, 0, 0],
              scale: 147
            }}
          >
            <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
            <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
            {data.length > 0 && (
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const d = data.find((s) => s.ISO3 === geo.id);
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={d ? colorScale(d["2017"]) : "#F5F4F6"}
                      />
                    );
                  })
                }
              </Geographies>
            )}
          </ComposableMap>
        </div>
        <div className="endSection">
          <p>All rights reserved © 2023</p>
          <p></p>
        </div>
      </div>
    </main>
  )
}

export default App